import { _callApi } from "./baseService";
import CryptoJS from "crypto-js";

export const getClientID = (email) => {
  let emailId = encodeURIComponent(email?.toLowerCase());
  return new Promise((resolve, reject) => {
    _callApi({}, "REACT_APP_CLIENTID_FETCH", "", 2, `?app=web&email=${emailId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data?.tokenTwo);
        } else {
          window.location.href = "/#/login";
          //reject(new Error("Failed to retrieve client ID"));
        }
      })
      .catch((error) => {
        window.location.href = "/#/login";
        //reject(error);
      });
  });
};

export const getKeys = (email) => {
  let emailId = encodeURIComponent(email?.toLowerCase());
  return new Promise((resolve, reject) => {
    _callApi({}, "REACT_APP_kEY_FETCH", "", 2, `?app=web&email=${emailId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(new Error("Failed to retrieve client ID"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const removeEscapeSequences = (text) => {
  return text.replace(/[^ -~]+/g, "");
};

const generateKey = (email, char) => {
  if (email?.length > char) {
    return email?.slice(-char);
  } else {
    const remainingChars = char - email?.length;
    const appendChars = Array.from({ length: remainingChars }, (_, index) =>
      (index + 1).toString()
    );
    const key = email + appendChars.join("");
    return key.slice(0, char);
  }
};

export const decrypt = (ciphertext, email) => {
  let emailId = email?.toLowerCase();
  const key = generateKey(emailId, 32);
  const iv = generateKey(emailId, 16);
  //const decodedCiphertext = CryptoJS.enc.Base64.parse(ciphertext);
  const Skey = CryptoJS.enc.Utf8.parse(key);
  const decrypted = CryptoJS.AES.decrypt(ciphertext, Skey, {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
  }).toString(CryptoJS.enc.Utf8);
  return decrypted;
};
