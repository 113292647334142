import React from "react";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

const LoadingOverlay = ({ show }) => {
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
      className="loadingOverlay"
    >
      <Loader
        type="Oval"
        color="#FFFFFF"
        height={40}
        width={40}
        className="graph-laoder"
      />
    </Modal>
  );
};

export default LoadingOverlay;
