/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useLayoutEffect } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { _callApi } from "src/Services/baseService";
import { Auth } from "aws-amplify";

const SetAdminPassword = () => {
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();
  const history = useHistory();
  // const [email, setEmail] = useState(null);
  // const [npi, setNpi] = useState(null);
  // const [pId, setPId] = useState(null);

  useLayoutEffect(() => {
    const handleLogout = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser && profile.email !== undefined) {
          await Auth.signOut();
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }

      let data = localStorage.getItem("browser-tab");
      localStorage.clear();
      localStorage.setItem("browser-tab", data);
    };

    handleLogout();
  }, []);

  // used for email verification link clicked
  useEffect(() => {
    let url = location.search.split("&");
    let clinicId = url[0]?.split("clinicId=")?.[1];
    let providerId = url[1]?.split("pId=")?.[1];
    getEmailId(clinicId, providerId);
  }, []);

  const getTherapistID = (email, npi, pId) => {
    var data = {
      email: email,
      npi: npi,
    };
    var headerdata = "5";
    _callApi(data, "REACT_APP_THERAPIST_REGISTRATION_URL", headerdata).then(
      (response) => {
        if (response?.status === 201) {
          let state = { tId: response?.data?.therapistId, pId: pId };
          history.push("/set-password", state);
        } else {
          history.push("/login");
        }
      }
    );
  };
  const getEmailId = (cId, pId) => {
    var data = {};
    var headerdata = "5";
    _callApi(
      data,
      "REACT_APP_CLINIC_PROVIDER",
      headerdata,
      2,
      `${cId}/${pId}`
    ).then((response) => {
      if (response.status === 200) {
        // setEmail(response.data.email);
        // setNpi(response.data.npi);
        // setPId(pId);

        getTherapistID(response.data.email, response.data.npi, pId);
      } else {
        history.push("/login");
      }
    });
  };

  return (
    <div>
      <Loader
        type="ThreeDots"
        color="var(--primaryColor)"
        height={40}
        width={40}
        className="graph-laoder"
      />
    </div>
  );
};

export default SetAdminPassword;
