import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

const DiscardFeedbackModal = ({ show, handleModalShowHide, handleSubmit }) => {
  return (
    <Modal
      className="form retun-feedbackPopup"
      show={show}
      // onHide={handleModalShowHide}
      centered
    >
      <Modal.Body>
        <Row>
          <Col>
            <p>Exit without documenting treatment management services?</p>
            <div className="retun-feedbackPopup--btn">
              <button
                type="button"
                className="btn btn-default "
                onClick={handleModalShowHide}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary "
                onClick={handleSubmit}
              >
                Yes
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DiscardFeedbackModal;
