import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import useCloseOnClickOutside from "src/hooks/useCloseOnClickOutside";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { _callApi } from "src/Services/baseService";
import { useSelector } from "react-redux";

const SearchSelect = ({
  htmlForId,
  label,
  value,
  //options,
  onChange,
  errors,
  branchSearchInput,
  handleBranchSearch,
  handleBranchSearchReset,
  clinics,
  fetchMoreclinics,
}) => {
  const clinicsInfo = useSelector((state) => state.user.clinicsInfo);
  const [showDropdown, setShowDropdown] = useState(false);
  const [clinicBranch, setClinicBranch] = useState("");
  const [options, setOptions] = useState([]);

  // const getNameFromId = (id) => {
  //   const foundItem = options.find((item) => item.id === id);
  //   return foundItem ? foundItem.name : "Branch not found";
  // };
  const getClinicInfoByID = async (id) => {
    try {
      const response = await _callApi(
        {},
        "REACT_APP_CLINIC_ROLES",
        "",
        2,
        `clinics/${id}`
      );
      if (response.status === 200) {
        return response.data.name;
      } else {
        console.error("API request failed");
        //throw new Error("API request failed");
        return false;
      }
    } catch (error) {
      console.error("Error fetching clinic information:", error);
      //throw new Error("API request failed");
      return false;
    }
  };

  const getNameFromId = async (id) => {
    const foundItem = options.find((item) => item.id === id);

    if (foundItem) {
      //return foundItem.name;
      setClinicBranch(foundItem.name);
    } else {
      const nameFromApi = await getClinicInfoByID(id);
      if (nameFromApi) {
        setClinicBranch(nameFromApi);
      } else {
        setClinicBranch("Branch not found");
      }
    }
  };

  useEffect(() => {
    // setClinicBranch(getNameFromId(value));
    if (value !== null) {
      getNameFromId(value);
      getClinicInfoByID(value);
    }
  }, [value, options]);
  useEffect(() => {
    if (clinicsInfo.length > 0) {
      let branch = clinicsInfo.map((branch, i) => {
        return {
          id: branch.clinicId,
          name: `${branch.clinicName}`,
        };
      });
      setOptions(branch);
    }
  }, [clinicsInfo]);
  let selectRef = useCloseOnClickOutside(() => {
    setShowDropdown(false);
  });

  return (
    <Form.Group as={Row} className="selectwithlabel" ref={selectRef}>
      <Col>
        <Form.Label htmlFor={htmlForId}>{label}</Form.Label>
        <div style={{ display: "flex" }}>
          <div style={{ paddingTop: "7px" }}>
            <svg
              width="13"
              height="19"
              viewBox="0 0 13 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.17188 0C2.76875 0 0 2.92188 0 6.51562C0 9.95312 5.55313 17.9031 5.7875 18.2375C5.83113 18.2988 5.88878 18.3487 5.95566 18.3832C6.0225 18.4177 6.09666 18.4357 6.17188 18.4357C6.24709 18.4357 6.32125 18.4177 6.38809 18.3832C6.45497 18.3487 6.51262 18.2988 6.55625 18.2375C6.79688 17.9031 12.3438 9.96562 12.3438 6.51562C12.3438 2.92188 9.575 0 6.17188 0ZM3.2875 6.51562C3.44375 2.69375 8.9125 2.69375 9.05625 6.51562C8.9 10.3406 3.44375 10.3375 3.2875 6.51562Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ display: "flex" }}>
            <div
              className={
                !showDropdown
                  ? "search-select-dropdown"
                  : "search-select-dropdown active"
              }
              onClick={() => {
                setShowDropdown(!showDropdown);
                handleBranchSearchReset();
              }}
              title={clinicBranch.length > 25 ? clinicBranch : ""}
            >
              {clinicBranch}
            </div>
            <div style={{ position: "relative" }}>
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="select-side"
              >
                <path
                  d="M0.122781 0.115942C0.288173 -0.0386473 0.556936 -0.0386473 0.722328 0.115942L5.9942 5.05314L11.2764 0.115942C11.4418 -0.0386473 11.7106 -0.0386473 11.876 0.115942C12.0413 0.270531 12.0413 0.521739 11.876 0.676329L6.30431 5.88406C6.22161 5.96135 6.11824 6 6.00454 6C5.90117 6 5.78746 5.96135 5.70476 5.88406L0.133119 0.676329C-0.0426101 0.521739 -0.0426108 0.270531 0.122781 0.115942V0.115942Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
        {showDropdown ? (
          <div className="search-select-container">
            <div className="select-dropdown-search">
              <div
                style={{
                  position: "relative",
                  top: "4px",
                  left: "10px",
                  height: "0px",
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 15.75L12.3855 12.3795M14.25 7.875C14.25 9.56576 13.5784 11.1873 12.3828 12.3828C11.1873 13.5784 9.56576 14.25 7.875 14.25C6.18424 14.25 4.56274 13.5784 3.36719 12.3828C2.17165 11.1873 1.5 9.56576 1.5 7.875C1.5 6.18424 2.17165 4.56274 3.36719 3.36719C4.56274 2.17165 6.18424 1.5 7.875 1.5C9.56576 1.5 11.1873 2.17165 12.3828 3.36719C13.5784 4.56274 14.25 6.18424 14.25 7.875V7.875Z"
                    stroke="#C4C4C4"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              <input
                type="text"
                className="search_field"
                placeholder="Search for Clinic..."
                value={branchSearchInput}
                onChange={(e) => {
                  handleBranchSearch(e);
                }}
              />
            </div>
            <div className="select-dropdown-values">
              {/* {options.length > 0
                ? options.map((optionvalue, i) => {
                    return (
                      <div
                        className="select-dropdown-value"
                        onClick={() => onChange(optionvalue.id)}
                      >
                        {optionvalue.name}
                      </div>
                    );
                  })
                : ""} */}
              <div
                className="clinics-scrollbar"
                style={{}}
                id="scrollableacDiv"
              >
                {clinics.isLoading ? (
                  <>
                    <Loader
                      type="ThreeDots"
                      color="var(--primaryColor)"
                      height={10}
                      width={40}
                      style={{
                        textAlign: "center",
                        paddingTop: "0px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <InfiniteScroll
                      dataLength={clinics.data.length}
                      next={fetchMoreclinics}
                      hasMore={clinics.hasMore}
                      loader={<span>Loading...</span>}
                      scrollableTarget="scrollableacDiv"
                      className="clinics-scrollbar-infi"
                    >
                      {clinics.data.length > 0 ? (
                        clinics.data.map((branch, index) => {
                          return (
                            <div
                              className="select-dropdown-value"
                              onClick={() => {
                                setShowDropdown(false);
                                onChange(branch.clinicId);
                              }}
                              key={index}
                              title={
                                branch.clinicName.length > 26
                                  ? branch.clinicName
                                  : ""
                              }
                            >
                              {branch.clinicName}
                            </div>
                          );
                        })
                      ) : (
                        <div style={{ paddingLeft: "20px" }}>
                          No Clinic Found
                        </div>
                      )}
                    </InfiniteScroll>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {errors[htmlForId] && errors[htmlForId].length > 0 ? (
          <div className="alert alert-danger" role="alert">
            {errors[htmlForId]}
          </div>
        ) : null}
      </Col>
    </Form.Group>
  );
};

export default SearchSelect;
