/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { Container, NavDropdown, Navbar, Nav, Row, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ActionCreators } from "src/actions/profile";
import Logo from "../Logo";
import Selects from "src/common-components/Selects";
import DiscardFeedbackModal from "../Modal/DiscardFeedbackModal";
import { getDashboard } from "../../utils/index";
import { _callApi } from "src/Services/baseService";
import { Auth } from "aws-amplify";
import SearchSelect from "src/common-components/SearchSelect";
import debounce from "lodash/debounce";
import { getProviderRoles, logoutAndRedirect } from "src/utils/common";
const Header = ({ username = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tabUid = useSelector((state) => state.user.tabUid);
  const profile = useSelector((state) => state.user.profile);
  const sessionDuration = useSelector((state) => state.user.sessionDuration);
  const isCareNavigator = useSelector((state) => state.user.isCareNavigator);
  const timerIsRunning = useSelector(
    (state) => state.user.stopWatchTimerIsRunning
  );
  const currentClinicID = useSelector((state) => state.user.currentClinicID);
  const currentParentClinicID = useSelector(
    (state) => state.user.currentParentClinicID
  );
  const userRoles = useSelector((state) => state.user.userRoles);
  const clinicsInfo = useSelector((state) => state.user.clinicsInfo);
  const config = useSelector((state) => state.user.config);
  const patExerciseModified = useSelector(
    (state) => state.user.patExerciseModified
  );
  const location = useLocation();
  const [branches, setBranches] = useState([]);
  const [redirectFrom, setRedirectFrom] = useState("/dashboard");
  const [fbDiscardModalShow, setFbDiscardModalShow] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState(null);
  const [selected, setSelected] = useState(null);
  const [branchTarget, setBranchTarget] = useState(null);
  const [branchSearchInput, setBranchSearchInput] = useState("");
  const [clinics, setClinics] = useState({
    totalRecords: 0,
    currentPageNumber: 0,
    totalPage: 0,
    data: [],
    isLoading: true,
    hasMore: true,
  });

  const [clinicsReset, setClinicsReset] = useState(false);
  const isCurrentURL = (url) => {
    return location.pathname.toLowerCase() === url.toLowerCase();
  };

  const handleBranchSelect = (value) => {
    if (timerIsRunning) {
      fbDiscardModalShowHide();
      setRedirectFrom("clinicBranch");
      setBranchTarget(value);
    } else {
      setSelectedBranches(value);
      localStorage.setItem("UserClinic", value);
      setSelected(value);
    }
  };

  // useEffect(() => {
  //   if (clinicsInfo.length > 0) {
  //     let branch = clinicsInfo.map((branch, i) => {
  //       return {
  //         id: branch.clinicId,
  //         name: `${branch.clinicName}`,
  //       };
  //     });
  //     setBranches(branch);
  //   }
  // }, [clinicsInfo]);
  useEffect(() => {
    if (selectedBranches !== null) {
      dispatch(ActionCreators?.setClinicBranch(selectedBranches));
    }
    // redirectToDashboard();
  }, [selectedBranches]);

  useEffect(async () => {
    if (currentClinicID) {
      setSelectedBranches(currentClinicID);
      let currentClinic = clinicsInfo.find(
        (branch) => branch.clinicId === currentClinicID
      );

      if (currentClinic) {
        dispatch(ActionCreators.setClinicTZ(currentClinic.timezone));
        dispatch(ActionCreators.setParentClinic(currentClinic.parentClinicId));
        localStorage.setItem("UserTimeZone", currentClinic.timezone);
        localStorage.setItem("UserParentClinic", currentClinic.parentClinicId);
        if (profile?.id) {
          try {
            await getProviderRoles(
              currentClinic.parentClinicId,
              currentClinic.clinicId,
              profile,
              dispatch,
              isCareNavigator
            );
          } catch (error) {
            dispatch(ActionCreators.setUserRoles([]));
            // logoutAndRedirect(profile, dispatch, tabUid, sessionDuration)
            //   .then(() => {
            //     history.push("/login");
            //   })
            //   .catch((error) => {
            //     console.error("Logout failed:", error);
            //   });
          }
        }
      }
    }
  }, [currentClinicID]);

  useEffect(() => {
    if (selected) {
      redirectToDashboard();
    }
  }, [selected, userRoles]);
  // useEffect(() => {
  //   // redirectToDashboard();

  // }, [userRoles]);

  const checkRedirectLogout = (e) => {
    e.preventDefault();
    const isEmpty = Object.keys(patExerciseModified)?.length === 0;
    if (!isEmpty) {
      dispatch(ActionCreators.setExerciseNotify(patExerciseModified));
    }
    if (timerIsRunning) {
      fbDiscardModalShowHide();
      setRedirectFrom("logout");
    } else {
      logoutAndRedirect(profile, dispatch, tabUid, sessionDuration)
        .then(() => {
          history.push("/login");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    }
  };

  const redirectToDashboard = () => {
    const isEmpty = Object.keys(patExerciseModified)?.length === 0;
    if (!isEmpty) {
      dispatch(ActionCreators.setExerciseNotify(patExerciseModified));
    }
    if (Object.keys(profile)?.length) {
      history.push(getDashboard(userRoles));
    }
  };

  const checkandRedirect = () => {
    // const isEmpty = Object.keys(patExerciseModified)?.length === 0;
    // if (!isEmpty) {
    //   dispatch(ActionCreators.setExerciseNotify(patExerciseModified));
    // }
    if (timerIsRunning) {
      fbDiscardModalShowHide();
      setRedirectFrom("/dashboard");
    } else {
      redirectToDashboard();
    }
  };
  const checkandRedirectTo = (page) => {
    const isEmpty = Object.keys(patExerciseModified)?.length === 0;
    if (!isEmpty) {
      dispatch(ActionCreators.setExerciseNotify(patExerciseModified));
    }
    if (timerIsRunning) {
      fbDiscardModalShowHide();
      setRedirectFrom(page);
    } else {
      history.push(page);
    }
  };
  const stopAndRedirect = () => {
    dispatch(ActionCreators.setTimerIsRunning(false));
    dispatch(ActionCreators.setStopWatchTimer(0, 0, 0, 0, false));
    if (redirectFrom === "/dashboard") {
      redirectToDashboard();
    } else if (redirectFrom === "/profile-setUp") {
      history.push("/profile-setUp");
    } else if (redirectFrom === "/on-boarding") {
      history.push("/on-boarding");
    } else if (redirectFrom === "logout") {
      logoutAndRedirect(profile, dispatch, tabUid, sessionDuration)
        .then(() => {
          history.push("/login");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    } else if (redirectFrom === "clinicBranch") {
      setSelectedBranches(branchTarget);
      localStorage.setItem("UserClinic", branchTarget);
      setSelected(branchTarget);
    } else {
      redirectToDashboard();
    }
  };
  const fbDiscardModalShowHide = () => {
    setFbDiscardModalShow(!fbDiscardModalShow);
  };
  const handleFBDiscardModalSubmit = () => {
    setFbDiscardModalShow(false);
    stopAndRedirect();
  };

  const handleDashboardonClick = async () => {
    if (clinicsInfo && clinicsInfo.length > 0) {
      const unwantedProfiles = [
        "SYSTEM ADMINISTRATOR",
        "SYSTEM_ADMINISTRATOR",
        "LOCAL_ADMIN",
        "REPORT",
      ];
      const clinics = clinicsInfo?.filter((clinic) =>
        clinic.profile?.some((profile) => !unwantedProfiles.includes(profile))
      );
      if (clinics?.length > 0) {
        try {
          const reponseRoles = await getProviderRoles(
            clinics?.[0].parentClinicId,
            clinics?.[0].clinicId,
            profile,
            dispatch,
            isCareNavigator
          );
          dispatch(ActionCreators?.setClinicBranch(clinics?.[0].clinicId));
          localStorage.setItem("UserClinic", clinics?.[0].clinicId);
          let pageType = getDashboard(reponseRoles);
          checkandRedirectTo(pageType);
        } catch (error) {
          console.log("failed to fetch roles", error);
        }
      } else {
        let pageType = getDashboard(userRoles);
        checkandRedirectTo(pageType);
      }
    } else {
      let pageType = getDashboard(userRoles);
      checkandRedirectTo(pageType);
    }
  };
  const getClinicsByEmail = (email, searchKey = "") => {
    let search = "";
    if (searchKey !== "") {
      search = `&filter=${searchKey}`;
    }
    var data = {};
    var headerdata = "";
    _callApi(
      data,
      "REACT_APP_GET_THERAPIST_URL",
      headerdata,
      2,
      `/clinics?user=${encodeURIComponent(email)}&pageNumber=${
        clinics.currentPageNumber
      }&pageSize=10${search}`
    ).then((response) => {
      if (response.status === 200) {
        if (response.data.pageContent?.length > 0) {
          setClinics({
            ...clinics,
            data: clinics.data.concat(response.data.pageContent),
            isLoading: false,
            totalRecords: response.data.totalRecords,
            totalPage: response.data.totalPage,
            currentPageNumber: clinics.currentPageNumber + 1,
            hasMore:
              response.data.pageContent.length > 0
                ? response.data?.pageNo + 1 === response.data?.totalPage
                  ? false
                  : true
                : false,
          });
        } else {
          setClinics({
            ...clinics,
            isLoading: false,
            hasMore: false,
          });
        }
      } else {
        setClinics({
          ...clinics,
          isLoading: false,
          hasMore: false,
        });
      }
    });
  };

  const fetchMoreclinics = () => {
    setTimeout(() => {
      if (
        clinics.data.length <= clinics.totalRecords &&
        clinics.currentPageNumber < clinics.totalPage
      ) {
        getClinicsByEmail(profile?.email, branchSearchInput);
      } else {
        setClinics({ ...clinics, hasMore: false });
      }
    }, 500);
  };

  const handleBranchSearchReset = () => {
    setBranchSearchInput("");
    setClinics({
      totalRecords: 0,
      currentPageNumber: 0,
      totalPage: 0,
      data: [],
      isLoading: true,
      hasMore: false,
    });
    setClinicsReset(true);
  };
  const handleBranchSearch = (e) => {
    const searchValue = e.target.value;
    setBranchSearchInput(searchValue);

    setClinics({
      totalRecords: 0,
      currentPageNumber: 0,
      totalPage: 0,
      data: [],
      isLoading: true,
      hasMore: false,
    });

    setClinicsReset(true);
  };
  useEffect(() => {
    if (clinicsReset) {
      setClinicsReset(false);

      getClinicsByEmail(profile?.email, branchSearchInput);
    }
  }, [clinicsReset]);

  // useEffect(() => {
  //   if (profile?.email !== undefined) {
  //     getClinicsByEmail(profile?.email);
  //   }
  // }, [profile?.email]);
  return (
    <header className="ymantraNav Site-header">
      <Container>
        <Navbar expand="lg">
          {/* <Navbar.Brand href=""><img src={logo} alt="Logo" onClick={redirectToDashboard}/></Navbar.Brand> */}
          <Navbar.Brand
            onClick={checkandRedirect}
            className={
              isCurrentURL("/login") ||
              isCurrentURL("/Register") ||
              isCurrentURL("/") ||
              isCurrentURL("/update-password")
                ? ""
                : "pointer"
            }
          >
            <Logo config={config} profile={profile} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-nav-dropdown" />
          <Navbar.Collapse>
            <Nav className="mr-auto d-flex align-items-end">
              {currentClinicID !== null &&
              !isCurrentURL("/login") &&
              !isCurrentURL("/admin-dashboard") &&
              !isCurrentURL("/reports-dashboard") &&
              clinicsInfo &&
              Object.keys(clinicsInfo)?.length > 1 ? (
                <>
                  <Row className="mr-5">
                    <Col>
                      <SearchSelect
                        onChange={handleBranchSelect}
                        errors={""}
                        value={selectedBranches}
                        htmlForId="clinicbranch"
                        type="select"
                        options={branches}
                        label="Location"
                        branchSearchInput={branchSearchInput}
                        handleBranchSearch={handleBranchSearch}
                        clinics={clinics}
                        fetchMoreclinics={fetchMoreclinics}
                        handleBranchSearchReset={handleBranchSearchReset}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
              {!isCurrentURL("/login") &&
              currentClinicID !== null &&
              profile &&
              Object.keys(profile)?.length ? (
                <>
                  <Nav.Link
                    onClick={() => checkandRedirectTo("/profile-setUp")}
                  >
                    {profile.name} {profile.family_name}{" "}
                  </Nav.Link>
                </>
              ) : currentParentClinicID !== null &&
                !isCurrentURL("/login") &&
                profile &&
                Object.keys(profile)?.length ? (
                <>
                  <Nav.Link
                    onClick={() => checkandRedirectTo("/profile-setUp")}
                  >
                    {profile.name} {profile.family_name}{" "}
                  </Nav.Link>
                </>
              ) : username ? (
                <Nav.Link href="">{username}</Nav.Link>
              ) : (
                <Nav.Link href=""></Nav.Link>
              )}
              {Object.keys(profile ?? [])?.length ? (
                <NavDropdown
                  title={<Icon.GearFill />}
                  id="responsive-nav-dropdown"
                >
                  {userRoles?.length === 1 &&
                  userRoles?.includes("sysadmin") ? (
                    ""
                  ) : (
                    <NavDropdown.Item onClick={handleDashboardonClick}>
                      {userRoles?.length === 1 && userRoles?.includes("Enroll")
                        ? "Patient Registration"
                        : "Dashboard"}
                    </NavDropdown.Item>
                  )}

                  {userRoles?.includes("Treat") ? (
                    <NavDropdown.Item
                      onClick={() => checkandRedirectTo("/library-console")}
                    >
                      Library Console
                    </NavDropdown.Item>
                  ) : null}
                  <NavDropdown.Item
                    onClick={() => checkandRedirectTo("/profile-setUp")}
                  >
                    Profile Set-Up
                  </NavDropdown.Item>
                  {userRoles?.includes("sysadmin") ? (
                    <NavDropdown.Item
                      onClick={() => checkandRedirectTo("/admin-dashboard")}
                    >
                      Admin Panel
                    </NavDropdown.Item>
                  ) : null}
                  {["Monitor", "Treat"].some((role) =>
                    userRoles?.includes(role)
                  ) ? (
                    <NavDropdown.Item
                      onClick={() => checkandRedirectTo("/on-boarding")}
                    >
                      Onboarding Tutorial
                    </NavDropdown.Item>
                  ) : null}

                  {
                    <NavDropdown.Item
                      onClick={() => checkandRedirectTo("/helpdesk")}
                    >
                      Help Desk
                    </NavDropdown.Item>
                  }
                  {userRoles?.includes("report") ? (
                    <NavDropdown.Item
                      onClick={() => checkandRedirectTo("/reports-dashboard")}
                    >
                      Reports
                    </NavDropdown.Item>
                  ) : null}
                  <NavDropdown.Item href="" onClick={checkRedirectLogout}>
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : !isCurrentURL("/") ? (
                !isCurrentURL("/login") && (
                  <NavDropdown
                    disabled={location.pathname
                      .toLowerCase()
                      .includes("/customexerciseupload")}
                    title={<Icon.GearFill />}
                    id="responsive-nav-dropdown"
                    className="d-flex align-items-center"
                  >
                    {!isCurrentURL("/") ? (
                      !isCurrentURL("/login") ? (
                        <NavDropdown.Item href="#/login">
                          Login
                        </NavDropdown.Item>
                      ) : null
                    ) : null}
                    {/* {!isCurrentURL("/Register") &&
                  !isCurrentURL("/set-password") ? (
                    <NavDropdown.Item href="#/register">
                      Register
                    </NavDropdown.Item>
                  ) : null} */}
                  </NavDropdown>
                )
              ) : (
                ""
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <DiscardFeedbackModal
          show={fbDiscardModalShow}
          handleModalShowHide={fbDiscardModalShowHide}
          handleSubmit={handleFBDiscardModalSubmit}
        />
      </Container>
    </header>
  );
};

export default Header;
