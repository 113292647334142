import { Auth } from "aws-amplify";

const configureAmplify = (userPoolWebClientId, type = "USER_PASSWORD_AUTH") => {
  Auth.configure({
    Auth: {
      cookieStorage: null,
    },
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: userPoolWebClientId,
    authenticationFlowType: type, //for mfa ->type="USER_PASSWORD_AUTH" DEFAULT
  });
};

export default configureAmplify;
