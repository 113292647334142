export const Types = {
  LOGIN: "LOGIN",
  ADD_USER: "ADD_USER",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_PROFILE_PICTURE: "UPDATE_PROFILE_PICTURE",
  FORM_SUBMITION_STATUS: "FORM_SUBMITION_STATUS",
  LOGOUT: "LOGOUT",
  ADD_PATIENT: "ADD_PATIENT",
  TIME_SPENT: "TIME_SPENT",
  GET_MOVEMENT_LIST: "GET_MOVEMENT_LIST",
  SET_CURRENT_CLINIC_BRANCH: "SET_CURRENT_CLINIC_BRANCH",
  SET_CURRENT_PARENT_CLINIC: "SET_CURRENT_PARENT_CLINIC",
  ADD_CLINIC_BRANCHS: "ADD_CLINIC_BRANCHS",
  TIMER_IS_RUNNING: "TIMER_IS_RUNNING",
  SET_STOPWATCH_TIMER: "SET_STOPWATCH_TIMER",
  SET_TIME_ZONE: "SET_TIME_ZONE",
  SET_USER_ROLES: "SET_USER_ROLES",
  CONFIG: "CONFIG",
  RESET_STORE: "RESET_STORE",
  SET_PAT_EXERCISE_MODIFY: "SET_PAT_EXERCISE_MODIFY",
  SET_PAT_EXE_MODI_NOTIFY: "SET_PAT_EXE_MODI_NOTIFY",
  TAB_UID: "TAB_UID",
  SESS_DURATION: "SESS_DURATION",
  TIMER_OUT: "TIMER_OUT",
  START_CN_INTERVAL: "START_CN_INTERVAL ",
  STOP_CN_INTERVAL: "STOP_CN_INTERVAL ",
  IS_ORPHAN_OPEN: "IS_ORPHAN_OPEN ",
  IS_CARE_NAVIGATOR: "IS_CARE_NAVIGATOR ",
};
