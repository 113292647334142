import { useEffect, useRef } from "react";

/**
 * Hook to close a component if clicked outside of that component
 *
 * @param  handler : callback that closes the component.
 * @returns domNode : a ref to the top element of the component
 *
 */

const useCloseOnClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let onOutsideClick = (event) => {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", onOutsideClick);

    return () => {
      document.removeEventListener("mousedown", onOutsideClick);
    };
  });

  return domNode;
};

export default useCloseOnClickOutside;
