export const FIRST_REQUIRED = "Please enter first name";
export const ALPHABET_ONLY = "Only alphabets are accepted";
export const MIN_REQUIRED_1 = "Passwords must have at least eight ";
export const MAX_REQUIRED_1 =
  " characters and must contain an uppercase letter,  ";
export const EXTRA_VALUE_1 = " lowercase letter, number, and special character";
export const MIN_REQUIRED = "Min length of ";
export const MAX_REQUIRED = " characters and max of ";
export const EXTRA_VALUE = " characters are accepted";
export const ROLE_REQUIRED = "Role is mandatory for registration";
export const LAST_REQUIRED = "Please enter last name";
export const LAST_REQUIRED_NEW = "Please enter last name";
export const FIRST_REQUIRED_NEW = "Please enter first name";
export const MIN_REQUIRED_USERNAME =
  "Username must be at least two characters long. Please enter a valid username.";
export const EMAIL_NOT_FOUND = "Unregistered email";
export const EMAIL_REQUIRED_LOGIN = "Email is mandatory for login";
export const EMAIL_REG_REQUIRED = "Please enter your email";
export const EMAIL_PATIENT_REQUIRED = "Please enter email";
export const EMR_REQUIRED = "Please enter MRN";
export const PHONE_REQUIRED = "Please enter phone number";
export const PHONE_VALID = "Invalid phone number";
export const PASSWORD_REQUIRED = "Please enter your password";
export const PASSWORD_REQUIRED_LOGIN = "Password is mandatory for login";
export const PASSWORD_MISMATCH = "Password mismatch";
export const PASSWORD_AND_CONFIRM_MISMATCH =
  "Password and confirm password fields do not match. Please make sure both fields contain the same password";
export const PASSWORD_MISMATCH_CONFIRM = "Please confirm your new password";
export const PASSWORD_ENTER = "Enter password";
export const EMAIL_VALID = "Invalid email";
export const PASSWORD_ACCEPT =
  "Password should contain  alphabets,special characters and numbers and are accepted";
// export const PASSWORD_VAILD =
//   "Password must be alphanumeric with upper and lower case letter along with special character";
// export const PASSWORD_VAILD = `Password must have an uppercase character and a lowercase character, a number and a special character in (! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\\ ] ^ _ \` { | } ~)`;
export const PASSWORD_VAILD =
  "Password must have at least eight (8) characters and must contain an uppercase letter,  lowercase letter, number and special character";
export const THERAPIST_REQUIRED =
  "Associated Therapist is mandatory for registration";
export const PURPOSE_INVALID =
  "Only alphabets, numbers and special characters are accepted as input";
export const USERNAME_REQUIRED = "User name is mandatory for login";
export const PASSCODE_REQUIRED = "Incorrect verification code";
export const PASSCODE_MINMAX_REQUIRED =
  "Passcode should be of 5 digits or 6 digits";
export const SALUTATION_REQUIRED = "Please select any salutation";
export const DESCRIPTION_REQUIRED = "Description is required";
export const FREQUENCY_REQUIRED = "Frequency is required";
export const PER_REQUIRED = "Per is required";
export const PURPOSE_REQUIRED = "Please enter a purpose";
export const NUMBER_ONLY = "Incorrect verification code";
export const NUMBER_ONLY_CLINIC = "Invalid zip code";
export const POSITIVE_NUMBER_ONLY = "Only positive numbers are accepted";
export const PATIENT_THERAPISTIDS_REQUIRED =
  "Associated Therapist is mandatory for registration";
export const GENDER_REQUIRED = "Gender field is mandatory for registration";
export const VERIFYCODE_REQUIRED = "Enter the verification code";
export const EMAIL_REQUIRED_RESET =
  "Email is mandatory to get reset password link";
export const PASSWORD_REQUIRED_NEW = "Please enter your new password";
export const PASSWORD_REQUIRED_NEW_CONFIRM = "Please confirm your new password";
export const PASSWORD_REQUIRED_NEWU =
  "New Password  is mandatory to update password";
export const PASSWORD_REQUIRED_NEWUC = "Please confirm your password";
export const CURRENT_PASSWORD_REQUIRED_NEW =
  "Please enter your current password";
export const FIRST_NPI_REQUIRED = "NPI ID is mandatory for registration";
export const NPI_ALLOWED = "Please enter your 10-digit NPI";
export const SPACES_ALLOWED = "Spaces are not allowed";
export const VIDEO_NOT_AVAILABLE = "Video is not available.";
export const PASSWORD_REQUIRED_SET = "Please enter your password";
export const INVALID_PASSWORD_SET =
  "Password should contain only alphabets, numbers and special characters";
export const PATIENT_REGISTRATION_UNSUCCESS =
  "Registration unsuccessful, kindly retry";
export const PATIENT_REGISTRATION_SUCCESS = "Patient Registration successful!";
export const PATIENT_REGISTRATION_ALREADY_HAD_TRY_LOGGING_APP =
  "A patient’s account has already been registered with this email. \nPatient may now try logging in from the App";
export const INCORRECT_USER_PASSWORD_LOGIN = "Incorrect password";
export const SOMETHING_WENT_WORNG = "Something went wrong!";
export const VERIFYCODE_EXPIRED = "Verification code expired. kindly retry";
export const MAX_ATTEMPTS = "No of attempts exceeded. kindly retry";
export const ATTEMPTS_LEFT =
  "Entered Verification code is incorrect. Remaining attempts: ";
export const FB_REQUIRED = "Please enter notes";
export const FB_MAX_LIMIT = "Maximum length should be 500 characters";
export const CLINIC_BRANCH_REQUIRED = "Please select clinic to proceed";
export const CONTACT_ADMIN =
  "Unable to process the request. kindly contact the system administrator";
export const NUMBER_ONLY_PROVIDER_NPI = "Invalid NPI";
export const NAME_REQUIRED_PROFILE = "Please enter custom Role name";
export const NAME_VALID_PROFILE = "Please enter a valid Role Name";
export const CLINIC_NAME_REQUIRED = "Please enter Clinic Name";
export const CLINIC_STREET_REQUIRED = "Please enter Street Name";
export const CLINIC_CITY_REQUIRED = "Please enter City Name";
export const CLINIC_EMAIL_REQUIRED = "Please enter email";
export const CLINIC_NPI_REQUIRED = "Please enter NPI";
export const CLINIC_PHONE_REQUIRED = "Please enter phone number";
export const CLINIC_STATE_REQUIRED = "Please enter State Name";
export const CLINIC_ZIPCODE_REQUIRED = "Please enter Zipcode";
export const CLINIC_ZIPCODE_VALID = "Please enter valid Zipcode";
export const PROVIDER_FNAME_REQUIRED = "Please enter first name ";
export const PROVIDER_LNAME_REQUIRED = "Please enter  last name";
export const EMAIL_DUPLICATE = "Email already exists";
export const FILE_SIZE = "Maximum size should be less than 2 MB";
export const FILE_ACCEPT = "Only svg, jpg, png files are accepted";
export const CHOOSE_ONE = "Existing User OR New User is required";
export const IC_NOTES_REQUIRED = "Please enter notes";
export const IC_DATE_REQUIRED = "Please enter Valid date";
export const IC_MIN_DURATION_REQUIRED = "Please enter call duratuon";
export const IC_SEC_DURATION_REQUIRED = "Please enter call duration";
// export const CALL_DURATION_REQUIRED = "Please enter call duration";
export const MESSAGE_REQUIRED = "Please enter message";
export const SUBJECT_REQUIRED = "Please enter subject";
export const MESSAGE_LIMIT_EXCEEDED = "You can't use more than 5000 characters";
export const SUBJECT_LIMIT_EXCEEDED = "You can't use more than 100 characters";
export const EXERCISE_NAME_REQUIRED = "Please enter exercise name";
export const EXERCISE_NAME_VALID = "Please enter valid exercise name";
export const EXERCISE_INSTRUCTIONS_REQUIRED = "Please enter instructions";
export const PRESCRIPTION_TYPE_REQUIRED = "Please enter prescription type";
export const FILE_SIZE_REQUIRED =
  "File size exceeds the maximum limit. Please choose a smaller file";
export const FILE_FORMAT_REQUIRED =
  "Invalid file format. Please upload an image or video file";
export const FILE_REQUIRED = "Please select a file for upload";
export const ROUTINE_NAME_REQUIRED = "Please enter routine name";
export const FIELD_REQUIRED = "Please configure:";
export const ROUTINE_ALREADY_EXISTS =
  "A routine with this title already exists. Please choose a different name";
export const CMOVEMENT_ALREADY_EXISTS =
  "A custom movement with this title already exists. Please choose a different name";
