import Moment from "moment-timezone";
// import configureStore from "../store/index";
// const store = configureStore();
// console.log(store.getState());
// "America/New_York"

let defaultTZ = "America/New_York";
if (
  localStorage.getItem("UserTimeZone") !== "null" &&
  localStorage.getItem("UserTimeZone") !== ""
) {
  defaultTZ = localStorage.getItem("UserTimeZone");
}
const UserMoment = Moment.tz.setDefault(defaultTZ);
export default UserMoment;
