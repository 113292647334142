import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import moment from "../../common/UserMoment";

import "./index.css";

const Footer = () => {
  return (
    <footer className="footer Site-footer">
      <Container>
        <Row>
          <Col sm="12">
            {/* Copyright &copy; yMantra 2021 */}
            Copyright &copy; ptMantra {moment().format("Y")}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
