import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import profile from "../reducers/profile";
import analytics from "../reducers/analytics";
// eslint-disable-next-line no-unused-vars
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "analytics"],
};
const rootReducer = combineReducers({
  user: profile,
  analytics: analytics,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
