export const ENROLL = "Enroll";
export const BILL = "Bill";
export const MONITOR = "Monitor";
export const TREAT = "Treat";
export const REPORT = "report";
export const SYSADMIN = "sysadmin";
export const DASHBOARD = [
  {
    role: "sysadmin",
    dashboard: "/admin-dashboard",
    weight: 5,
  },
  {
    role: "Enroll",
    dashboard: "/add-patient",
    weight: 4,
  },
  {
    role: "Bill",
    dashboard: "/bill-dashboard",
    weight: 3,
  },
  {
    role: "Monitor",
    dashboard: "/dashboard",
    weight: 2,
  },
  {
    role: "Treat",
    dashboard: "/dashboard",
    weight: 1,
  },
];
