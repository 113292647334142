import { Types } from "../constants/actionTypes";
import { _callApi } from "src/Services/baseService";

export const ActionCreators = {
  addProfile: (user) => {
    return { type: Types.ADD_USER, payload: { user } };
  },
  addPatient: (patient) => ({ type: Types.ADD_PATIENT, payload: { patient } }),

  updateProfileImage: (image) => ({
    type: Types.UPDATE_PROFILE_PICTURE,
    payload: { image },
  }),

  updateProfile: (user) => ({ type: Types.UPDATE_USER, payload: { user } }),

  formSubmittionStatus: (status) => ({
    type: Types.FORM_SUBMITION_STATUS,
    payload: { status },
  }),

  login: (user) => ({ type: Types.LOGIN, payload: { user } }),
  logout: (user) => ({ type: Types.LOGOUT, payload: {} }),
  timespent: (data, therapistId) => async (dispatch) => {
    let response = await _callApi(
      data,
      "REACT_APP_THERAPIST_TIMESPENT",
      "",
      1,
      `/${therapistId}`
    );
    if (response.data.statusCode === "201") {
      return dispatch({
        type: Types.TIME_SPENT,
        payload: {
          success: true,
          error: null,
          action: data.action,
        },
      });
    } else {
      return dispatch({
        type: Types.TIME_SPENT,
        payload: {
          success: null,
          error: true,
          action: data.action,
        },
      });
    }
  },
  setClinicBranch: (id) => ({
    type: Types.SET_CURRENT_CLINIC_BRANCH,
    payload: id,
  }),
  setParentClinic: (id) => ({
    type: Types.SET_CURRENT_PARENT_CLINIC,
    payload: id,
  }),
  setClinicTZ: (zone) => ({
    type: Types.SET_TIME_ZONE,
    payload: zone,
  }),
  addClinicBranchs: (branchs) => ({
    type: Types.ADD_CLINIC_BRANCHS,
    payload: branchs,
  }),
  setTimerIsRunning: (bol) => ({
    type: Types.TIMER_IS_RUNNING,
    payload: bol,
  }),
  setStopWatchTimer: (seconds, minutes, hours, days, isRunning) => ({
    type: Types.SET_STOPWATCH_TIMER,
    payload: { seconds, minutes, hours, days, isRunning },
  }),
  setUserRoles: (role) => ({
    type: Types.SET_USER_ROLES,
    payload: role,
  }),
  // setConfig: (data) => ({
  //   type: Types.CONFIG,
  //   payload: data,
  // }),
  setConfig: (branchId, parentClinicId) => async (dispatch) => {
    let response = await _callApi(
      {},
      "REACT_APP_CLINIC_ROLES",
      "",
      2,
      `parent/clinic/${parentClinicId}/config?branchId=${branchId}`
    );
    if (parseInt(response.status) === 200) {
      return dispatch({
        type: Types.CONFIG,
        payload: response.data,
      });
    } else {
      return dispatch({
        type: Types.CONFIG,
        payload: {},
      });
    }
  },
  resetStore: (uid) => ({
    type: Types.RESET_STORE,
    payload: uid,
  }),
  setPatModifiedExercise: (data) => ({
    type: Types.SET_PAT_EXERCISE_MODIFY,
    payload: data,
  }),
  setExerciseNotify: (modifyData) => async (dispatch) => {
    try {
      let patientId = modifyData?.patientId;
      let episodeNumber = modifyData?.episodeNumber;
      let data = {
        clinicId: modifyData?.clinicId,
        exercises: modifyData?.exercises,
        therapistId: modifyData?.therapistId,
      };
      let response = await _callApi(
        data,
        "REACT_APP_UPDATE_PATIENT",
        "",
        1,
        `/${patientId}/${episodeNumber}/notification`
      );
      if (parseInt(response.status) === 200) {
        dispatch({
          type: Types.SET_PAT_EXERCISE_MODIFY,
          payload: {},
        });
        dispatch({
          type: Types.SET_PAT_EXE_MODI_NOTIFY,
          payload: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  },
  setTabUid: (uid) => ({
    type: Types.TAB_UID,
    payload: uid,
  }),
  setLoginTime: (date, clinic, uuid) => ({
    type: Types.SESS_DURATION,
    payload: {
      loginTime: date,
      isLoggedIn: true,
      clinicId: clinic,
      uuid: uuid,
    },
  }),
  setLogoutTime: (date) => ({
    type: Types.SESS_DURATION,
    payload: { logoutTime: date, isLoggedIn: false },
  }),
  setTimerOut: (tout) => ({
    type: Types.TIMER_OUT,
    payload: tout,
  }),
  startCNInterval: (cnTimeInit) => ({
    type: Types.START_CN_INTERVAL,
    payload: cnTimeInit,
  }),
  stopCNInterval: () => ({
    type: Types.STOP_CN_INTERVAL,
  }),
  setOrphanOpen: (isopen) => ({
    type: Types.IS_ORPHAN_OPEN,
    payload: isopen,
  }),
  setIsCNavigator: (iscn) => ({
    type: Types.IS_CARE_NAVIGATOR,
    payload: iscn,
  }),
};
